angular.module("LeasePilot").controller("ManageUsersController", [
  "$scope",
  "$mdDialog",
  "UsersService",
  "UserHelperService",
  function($scope, $mdDialog, UsersService, UserHelperService) {
    $scope.init = async function() {
      $scope.isProcessing = false;
      
      $scope.users.forEach(u => {
        if (window.user.id === u.id) {
          u.isCurrentUser = true;
        }
      });
    };

    $scope.initUpdate = function(userId) {
      $scope.id = userId;

      const counter = setInterval(() => {
        if (window.user) {
          clearInterval(counter);
          
          if (userId === window.user.id) {
            $scope.safeApply(() => {
              $scope.isCurrentUser = true;
            });
          }
        }
      }, 250);
    };

    $scope.showResetPasswordDialog = function(user) {
      $scope.currentUser = user;
      $mdDialog.show({
        template: `<md-dialog><reset-password-modal id="${user.id}" email="${user.email}" /></md-dialog>`,
        parent: angular.element(document.body),
        fullscreen: false
      });
    };

    $scope.activateUser = function(user) {
      UserHelperService.activate(user.id).then(
        response => {
          window.location.reload();
        },
        error => {
          throw error;
        }
      );
    };

    $scope.deactivateUser = function(user) {
      UserHelperService.deactivate(user.id).then(
        response => {
          window.location.reload();
        },
        error => {
          throw error;
        }
      );
    };

    $scope.showDeleteUserDialog = function(user) {
      $scope.currentUser = user;
      $mdDialog.show({
        template: `<md-dialog><delete-user-confirmation-modal id="${user.id}" email="${user.email}" /></md-dialog>`,
        parent: angular.element(document.body),
        fullscreen: false
      });
    };

    $scope.create = async function() {
      const isValid = await UserHelperService.validate({
        type: "create",
        firstName: $scope.firstName,
        lastName: $scope.lastName,
        email: $scope.email,
        password: $scope.password,
        role: $scope.role,
      });

      $scope.isValid = isValid;

      if (isValid.all) {
        $scope.safeApply(() => {
          $scope.isProcessing = true;
        });
        
        const promise = UserHelperService.create({
          firstName: $scope.firstName,
          lastName: $scope.lastName,
          email: $scope.email,
          password: $scope.password,
          role: $scope.role,
        });
        
        promise.then(
          response => {
            window.location.href = "/manage_forms/users";
          },
          error => {
            console.log(error);
            // TODO:
          }
        );
      }
    };

    $scope.update = async function() {
      const isValid = await UserHelperService.validate({
        type: "update",
        firstName: $scope.firstName,
        lastName: $scope.lastName,
        email: $scope.email,
        role: $scope.role,
      });

      $scope.isValid = isValid;

      if (isValid.all) {
        $scope.safeApply(() => {
          $scope.isProcessing = true;
        });

        const promise = UserHelperService.update({
          id: $scope.id,
          firstName: $scope.firstName,
          lastName: $scope.lastName,
          email: $scope.email,
          role: $scope.role,
        });
        
        promise.then(
          response => {
            window.location.href = "/manage_forms/users";
          },
          error => {
            console.log(error);
            // TODO:
          }
        );
      }
    };

    $scope.generatePassword = async function() {
      $scope.password = UserHelperService.generatePassword();
      await $scope.validateBeforeCreate("create");

      setTimeout(() => {
        document.querySelector("#password").select();
      });
    };

    $scope.validateBeforeCreate = async function() {
      const isValid = await UserHelperService.validate({
        type: "create",
        firstName: $scope.firstName || "",
        lastName: $scope.lastName || "",
        email: $scope.email || "",
        password: $scope.password || "",
        role: $scope.role
      });

      $scope.safeApply(() => {
        $scope.isValid = isValid;
      });
    };

    $scope.validateBeforeUpdate = async function() {
      const isValid = await UserHelperService.validate({
        type: "update",
        firstName: $scope.firstName || "",
        lastName: $scope.lastName || "",
        email: $scope.email || "",
        role: $scope.role
      });

      $scope.safeApply(() => {
        $scope.isValid = isValid;
      });
    };

    $scope.cancel = function() {
      window.location.href = "/manage_forms/users";
    };
  }
]);
